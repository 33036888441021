import Vue from 'vue'
import App from './App.vue'
import './styles/index.css' // global css

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import axios from 'axios';

import moment from 'moment'
Vue.prototype.$moment = moment

if(localStorage.id){
    store.commit('SET_cityId',localStorage.id)
}

let domain = document.domain;
//let baseURL="https://api.yaojian.xusos.com/api"
let baseURL="/api"
axios.defaults.baseURL =baseURL;  //请求的默认地址
axios.defaults.headers.common['Authorization'] = "AUTH_TOKEN";

import Cookies from 'js-cookie'

Vue.prototype.$Cookies = Cookies
Vue.prototype.$login=false   //true需要登录，false不需要登录
let userInfo=""
let id=""
console.log(Cookies.get("userInfo"),"kk")
if(Cookies.get("userInfo")){
    userInfo=JSON.parse(Cookies.get("userInfo"))
    id=userInfo.regionCode
}else{
   id="330726"
}

Vue.prototype.$id = id
Vue.prototype.$api=baseURL
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'SR4XOvuAhNfufh25pCumRCgMU8sbVM2z'
})

// 图片域名替换
Vue.prototype.$getImageUrl = (url) => {
    if (url != null && url != '' && url.indexOf('http') == -1) {
        return '/images' + url.substring(firstIndex)
    }
    return url
}

Vue.config.productionTip = false
import _XEUtils_ from 'xe-utils'

Vue.prototype.$x = _XEUtils_
Vue.prototype.$city = [
    {
        code:"330000",
        name:"浙江省",
        longitude:"119.656028",
        latitude:"29.089564",
        zoom:7.5
    },
    {
        code:"330700",
        name:"金华市",
        longitude:"119.93",
        latitude:"29.09",
        zoom:9.2
    },
    {
        code:"330726",
        name:"浦江县",
        longitude:"119.905489",
        latitude:"29.478191",
        zoom:11.2
    },
    {
        code:"330783",
        name:"东阳市",
        longitude:"120.34",
        latitude:"29.29",
        zoom:10.6
    },
    {
        code:"330703",
        name:"金东区",
        longitude:"119.79",
        latitude:"29.10",
        zoom:11.2
    },
    {
        code:"330781",
        name:"兰溪市",
        longitude:"119.46",
        latitude:"29.21",
        zoom:11.2
    },
    {
        code:"330727",
        name:"磐安县",
        longitude:"120.55",
        latitude:"29.05",
        zoom:10.9
    },
    {
        code:"330702",
        name:"婺城区",
        longitude:"119.47",
        latitude:"28.89",
        zoom:10.8
    },
    {
        code:"330723",
        name:"武义县",
        longitude:"119.72",
        latitude:"28.69",
        zoom:10.6
    },
    {
        code:"330782",
        name:"义乌市",
        longitude:"120.03",
        latitude:"29.23",
        zoom:11.0
    },
    {
        code:"330784",
        name:"永康市",
        longitude:"120.05",
        latitude:"28.89",
        zoom:11.2
    },
    {
        code:"330751",
        name:"金华市",
        longitude:"119.65",
        latitude:"29.08",
        zoom:11.2
    },
    {
        code:"330753",
        name:"金华市",
        longitude:"119.65",
        latitude:"29.08",
        zoom:11.2
    },
]

router.beforeEach((to,from,next)=>{
/*  if(to.meta.title){
   document.title=to.meta.title
    next()
  }else{
      next()
  }*/
    document.title="金药匙·云享药房"
    next()
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
